.location-icon{
  background-color: #4285F4;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  margin-left: -10px !important;
  margin-top: -10px !important;
  width: 16px !important;
  height: 16px !important;
};